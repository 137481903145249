.paragraph--service_produit,
.paragraph--service_produit__list {
	h2 {
		margin-bottom: 55px;
		color: #1D1D1B;
		line-height: 1.15;
		text-align: center;
	}
}

.paragraph--service_produit {
	padding-top: 65px;
	padding-bottom: 30px;
}

.paragraph--service_produit__list {
	padding-top: 70px;
  	padding-bottom: 165px;
  	.products {
		display: flex;
	    flex-wrap: wrap;
	    justify-content: center;
	    margin-bottom: 0;
	    margin-left: -15px;
	    margin-right: -15px;
	    padding: 0;
	    list-style: none;
		.item-product_wrapper {
			flex: 0 0 100%;
		    max-width: 100%;
		    padding-left: 15px;
		    padding-right: 15px;
		    width: 100%;

		    @include mq-up($lg) {
		      flex: 0 0 50%;
		      max-width: 50%;
		    }

		    @include mq-up($xl) {
		      flex: 0 0 33.33333%;
		      max-width: 33.33333%;
		    }
		}
	}
}

.item-product {
	display: block;
	margin: 15px 0;
	border-radius: 0 0 4px 4px;
	height: 405px;
	background-color: #F6F4F0;
	text-decoration: none !important;
	overflow: hidden;
	.img-wrapper {
		position: relative;
		height: 206px;
		background-color: #000;
		overflow: hidden;
		.bg {
			transition: all .35s ease-out;
		}
	}
	.content {
		padding: 15px;
		color: $color-body;
		@include mq-up($md) {
            padding: 25px;
        }
		@media (min-width: 1320px) {
			padding: 40px;
		}

		h3, p {
			margin-bottom: 0;
		}
		h3 {
			color: #212121;
			font-size: 1.15rem;
			line-height: 1.3;
			transition: all .35s ease-out;
		}
		.link {
			transition: all .35s ease-out;
		}
	}
	&:hover {
		.img-wrapper {
			.bg {
				transform: scale(1.1);
			}
		}
		.content {
			h3 {
				color: $primary;
			}
			.link {
				text-decoration: none;
			}
		}
	}
}