.paragraph--faq {
	padding-top: 50px;
	padding-bottom: 90px;
	h2 {
		margin-bottom: 55px;
	}

	.accordion {
		.itemAccordion {
			position: relative;
			overflow: hidden;
			input {
				position: absolute;
				z-index: -1;
				opacity: 0;
			}
			label {
				display: block;
				position: relative;
				margin: 0;
				border-bottom: 1px solid #E5E5E5;
				padding: 15px 0;
				padding-right: 30px;
				color: #212121;
				font-size: 1.6rem;
				cursor: pointer;
				transition: all .35s;
				&:after {
					content: "+";
					display: block;
					position: absolute;
					top: 50%;
					right: 10px;
					margin-top: -10px;
					font-size: 1rem;
					line-height: 20px;
					transition: all .35s;
				}
			}
			.itemAccordionContent {
				max-height: 0;
				transition: max-height .35s;
				.inner {
					padding-top: 15px;
					padding-bottom: 40px;
				}
			}

			/* :checked */
			input:checked {
				~ .itemAccordionContent {
				}
				& + label {
					padding-bottom: 20px;
					&:after {
						transform: rotate(225deg);
					}
				}
			}
		}
	}
}
