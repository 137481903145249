// -------------------------------------------------------------
//   Variables
// -------------------------------------------------------------

// Color system

$primary: #ee352c;
$secondary: #a722bb;
$dark-secondary: #75357f;
$tertiary: #bf427d;
$dark-tertiary: #9c295f;

$color-body: #212529;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,400i,500,500i,600,600i,700,700i');
$ff-body: 'Montserrat', sans-serif;

// Grid

$xs: 0;
$sm: 540px;
$md: 768px;
$lg: 990px;
$xl: 1200px;
$xxl: 1440px;