.paragraph--img_txt {
 padding-top: 35px;
 padding-bottom: 35px;
 .content-wrapper {
   display: grid;
   @include mq-up($md) {
     grid-template-columns: repeat(2, 1fr);
   }
 }
 .img-wrapper {
   img {
     max-width: 100%;
     height: auto;
   }
   @include mq-up($md) {
     position: relative;
     overflow: hidden;
     img {
       @include center(both);
       width: auto;
       min-width: 100%;
       max-width: none;
       height: auto;
       min-height: 100%;
     }
   }
 }
 .content {
   padding: 30px 0 0;
   @include mq-up($md) {
     padding: 55px 30px 60px;
   }
   @include mq-up($lg) {
     padding-left: 60px;
     padding-right: 60px;
   }
   @include mq-up($xl) {
     padding-left: 80px;
     padding-right: 80px;
   }
   > * {
     max-width: 350px;
   }
 }

 p {
   margin-bottom: 50px;
   &:last-child {
     margin-bottom: 0;
   }
 }

 &.img-align_right {
    .img-wrapper {
     @include mq-up($md) {
       order: 1;
     }
    }
    .content {
     @include mq-up($md) {
       order: 0;
     }
     @include mq-up($xl) {
       padding-left: 160px;
     }
    }
 }
}