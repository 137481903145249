.paragraph--cta {
	margin-bottom: 30px;
	padding-top: 40px;
	background-color: $primary;
	color: #fff;
	text-align: center;

	h2,.h2 {
		margin-bottom: 15px;
		color: #fff;
	}

	.btn {
		margin-bottom: -30px;
		padding: 17px 50px;
	}
}
