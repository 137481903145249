.pager {
  ul {
    display: flex;
    border-radius: .25rem;
    padding-left: 0;
    list-style: none;
    li {
      a {
        display: block;
        position: relative;
        margin-left: -1px;
        border: 1px solid #dee2e6;
        padding: .5rem .75rem;
        background-color: #fff;
        color: $primary;
        line-height: 1.25;
        text-decoration: none;
      }
      
      &:hover {
        a {
          z-index: 2;
          border-color: #dee2e6;
          background-color: #e9ecef;
          color: darken($primary, 15%);
          text-decoration: none;
        }
      }
      &:focus {
        a {
          z-index: 2;
          box-shadow: 0 0 0 .2rem rgba($primary, .25);
          outline: 0;
        }
      }

      &:first-child {
        a {
          margin-left: 0;
          border-top-left-radius: .25rem;
          border-bottom-left-radius: .25rem;
        }
      }
      &:last-child {
        a {
          border-top-right-radius: .25rem;
          border-bottom-right-radius: .25rem;
        }
      }

      &.active a {
        z-index: 1;
        border-color: $primary;
        background-color: $primary;
        color: #fff;
      }
    }
  }
}
