.paragraph--evidence {
  padding-top: 75px;
  padding-bottom: 60px;
  .highlights {
    display: flex;
    flex-wrap: wrap;
  }
  .highlight {
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    .content {
      position: relative;
      z-index: 10;
      color: #fff;

      h2, h3, h4, h5, h6,
      .h2, .h3, .h4, .h5, .h6 {
        margin-bottom: 30px;
        color: #fff;
        &:last-child {
          margin-bottom: 0;
        }
      }
      p, ul, ol {
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      a:not([class*="btn"]) {
        color: #fff;
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
    &.primary {
      background-color: $primary;
    }
    &.image {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        position: absolute;
        z-index: 5;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $secondary;
        opacity: .55;
      }
    }
    &:only-child {
      position: relative;
      .content {
        padding: 110px 8.75% 105px;
        h2, h3, h4, h5, h6,
        .h2, .h3, .h4, .h5, .h6 {
          line-height: 1.35;
        }
        &.withBtn {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          .text {
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;
            
            @include mq-up($md) {
              flex: 0 0 66.66667%;
              max-width: 66.66667%;
              padding-right: 15px;
            }
          }
          .cta {
            margin-top: 30px;
            flex: 0 0 100%;
            max-width: 100%;
            width: 100%;

            @include mq-up($md) {
              flex: 0 0 33.33333%;
              max-width: 33.33333%;
              margin-top: 0;
              padding-left: 15px;
              text-align: right;
            }
          }
        }
      }
      &.sm {
        .content {
          padding-top: 55px;
          padding-bottom: 50px;
        }
      }
      &.primary {
        .btn {
          @extend .btn-secondary;
        }
      }
    }
    &:not(:only-child) {
      @include mq-between($xs, $sm) {
        &:nth-child(even) {
          background-color: $secondary;
        }
      }
      @include mq-between($sm, $lg) {
        &:nth-child(4n+2) {
          background-color: $secondary;
        }
        &:nth-child(4n+3) {
          background-color: $secondary;
        }
      }
      @include mq-up($lg) {
        &:nth-child(even) {
          background-color: $secondary;
        }
      }

      @include mq-up($sm) {
        flex: 0 0 50%;
        max-width: 50%;
      }
      @include mq-up($lg) {
        flex: 0 0 33.33333%;
        max-width: 33.33333%;
      }
      @include mq-up($xl) {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
      }
      .content {
        margin-left: auto;
        margin-right: auto;
        padding: 60px 15px 75px;
        max-width: 400px;

        h2, h3, h4, h5, h6,
        .h2, .h3, .h4, .h5, .h6 {
          line-height: 1;
        }

        .btn {
          background-color: transparent !important;
          border-color: #fff !important;
          color: #fff !important;
          &:hover {
            background-color: #fff !important;
            border-color: #fff !important;
            color: $primary !important;
          }
        }
      }
      .cta {
        margin-top: 15px;
      }
    }
  }
}
