// -------------------------------------------------------------
//   Main header
//   Header styles
// -------------------------------------------------------------

.main-header {
	position: fixed;
	z-index: 400;
	top: 0;
	left: 0;
	width: 100%;
	transition: all .25s ease-in-out;

	.section-wrapper {
		position: relative;
	}

	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: flex-start;
		justify-content: center;
		position: relative;
		@include mq-up($lg) {
			justify-content: space-between;
		}
	}

	.logo {
		position: relative;
		z-index: 5;
		color: #bbbec2;
		font-size: 1.25rem;
		font-weight: 700;
		letter-spacing: .25px;
		line-height: normal;
		text-transform: uppercase;
		@include mq-up($lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
		}
		> a {
			display: block;
			padding: 20px 30px;
			width: 105px;
			background-color: #fff;
			box-shadow: 0px 40px 80px 0px rgba(0, 0, 0, 0.15);
			transition: all .25s ease-in-out;
			img {
				max-width: 100%;
				height: auto;
			}
		}
		> span {
			display: block;
			margin-top: 10px;
			@include mq-up($lg) {
				margin-top: 0;
				margin-left: 25px;
			}
		}
	}

	.main-menu {
		padding: 0 40px;
		background-color: #fff;
		@include mq-up($lg) {
			padding: 75px 0 0;
			background-color: transparent;
		}
		> ul {
			margin: 0;
	        padding: 0;
	        list-style: none;
	        @include mq-up($lg) {
				display: flex;
    			flex-wrap: wrap;
    			align-items: center;
	        }

	        > li {
	        	position: relative;

		        > a, > span:not([class*="expand"]) {
					display: block;
	    			padding: 20px 10px;
					color: #5e5e5e;
					font-size: 0.875rem;
	    			font-weight: 600;
	    			line-height: 1.45;
	        		text-decoration: none;
	        		text-transform: uppercase;
	        		transition: all .25s ease-in-out;
	        		cursor: pointer;
	        		&:after {
	        			content: "";
	        			position: absolute;
						top: 50%;
						right: 10px;
						width: 5px;
						height: 8px;
						@include svgBackground("0 0 256 512", "#232020", "M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z");
						background-position: left top;
						background-repeat: no-repeat;
						background-size: 5px auto;
						transform: translateY(-50%);
	        		}
	        		@include mq-up($lg) {
						padding: 10px 0;
						&:after {
							display: none;
						}
	        		}
		        }

		        &:hover, &.active, &.active-trail {
	                > a, > span:not([class*="expand"]) {
	                    color: $primary;
	                    &:after {
	                    	@include svgBackground("0 0 256 512", $primary, "M224.3 273l-136 136c-9.4 9.4-24.6 9.4-33.9 0l-22.6-22.6c-9.4-9.4-9.4-24.6 0-33.9l96.4-96.4-96.4-96.4c-9.4-9.4-9.4-24.6 0-33.9L54.3 103c9.4-9.4 24.6-9.4 33.9 0l136 136c9.5 9.4 9.5 24.6.1 34z");
	                    }
	                }
	            }

	            & + li {
	            	border-top: 1px solid #e5e5e5;
	            	@include mq-up($lg) {
			            margin-left: 15px;
			            border-top: 0;
			        }
	            }

	            @include mq-up($lg) {
			        &:hover {
	                    > ul {
	                        opacity: 1;
	                        visibility: visible;
	                    }
	                }
			    }

	            // Niv 2
	            > ul {
	            	display: none;
	                margin: 0;
	                padding: 10px 0;
	                list-style: none;
	                @include mq-up($lg) {
	                	display: block;
	                    position: absolute;
		                z-index: 10;
		                top: 100%;
		                left: 50%;
		                border-radius: 4px;
                    	width: 220px;
                    	min-width: 100%;
                    	background-color: #fff;
						// box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2);
				        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
                    	opacity: 0;
                		visibility: hidden;
                		transform: translateX(-50%);
                    	transition: all .25s ease-in-out;
                    	&:before {
                    		content: "";
                    		position: absolute;
                    		top: -10px;
                    		left: 50%;
                    		margin-left: -10px;
                    		width: 0;
							height: 0;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-bottom: 10px solid #fff;
                    	}
	                }

	                > li {
	                    > a, > span:not([class*="expand"]) {
                			display: block;
			    			padding: 0 30px;
							color: #5e5e5e;
							font-size: 0.875rem;
			    			font-weight: 600;
			    			line-height: 1.45;
			        		text-decoration: none;
			        		transition: all .25s ease-in-out;
			        		cursor: pointer;
			        		@include mq-up($lg) {
								padding: 5px 20px;
			        		}
	                    }

	                    &:hover, &.active {
	                        > a, > span:not([class*="expand"]) {
	                        	color: $primary;
	                        }
	                    }
	                }
	            }
	        }
		}
	}

	.top {
		@include mq-up($lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: flex-end;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
		}
	}

	.ctas {
		padding: 25px 40px 10px;
		background-color: #f3f3f3;
		@include mq-up($lg) {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			padding: 0;
			background-color: transparent;
		}
		.cta {
			> a {
				display: block;
				padding: 0 10px;
				color: $primary;
				font-size: 0.875rem;
				font-weight: 400;
				line-height: 1.5;
				text-decoration: none;
				@include mq-up($lg) {
					padding: 0;
				}
				span {
					text-decoration: underline;
				}
				&:hover {
					span {
						text-decoration: none;
					}
				}
			}
		}
	}

	.top-menu, .user-menu {
		padding: 0 40px;
		background-color: #f3f3f3;
		@include mq-up($lg) {
			padding: 0;
			background-color: transparent;
		}
		> ul {
			margin: 0;
	        padding: 0;
	        list-style: none;
	        @include mq-up($lg) {
				display: flex;
    			flex-wrap: wrap;
    			align-items: center;
	        }

	        > li {
	        	position: relative;

		        > a, > span:not([class*="expand"]) {
					display: block;
	    			padding: 0 10px;
					color: #5a5a5a;
					font-size: 0.875rem;
					font-weight: 400;
					line-height: 1.5;
	        		text-decoration: none;
	        		transition: all .25s ease-in-out;
	        		cursor: pointer;
	        		@include mq-up($lg) {
						padding: 10px 0;
	        		}
		        }

		        &:hover, &.active, &.active-trail {
	                > a, > span:not([class*="expand"]) {
	                    color: $primary;
	                }
	            }

	            &.user {
	            	> a, > span:not([class*="expand"]) {
	            		@include mq-up($lg) {
			            	display: flex;
			    			flex-wrap: wrap;
			    			align-items: center;
			    		}
		    			.profil-user {
		    				@include mq-up($lg) {
			    				margin-right: 15px;
			    			}
		    			}
		    			span {
		    				padding-right: 15px;
		    				@include svgBackground("0 0 320 512", "#5a5a5a", "M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z");
		    				background-position: right center;
		    				background-repeat: no-repeat;
		    				background-size: 8px auto;
		    			}
		    		}
	            }

	            & + li {
	            	@include mq-up($lg) {
			            margin-left: 15px;
			        }
	            }

		        &:hover {
	            	@include mq-up($lg) {
	                    > ul {
	                        opacity: 1;
	                        visibility: visible;
	                    }
	                }
			    }

	            // Niv 2
	            > ul {
	                margin: 0;
	                padding: 0;
	                list-style: none;
	                @include mq-up($lg) {
	                	display: block;
	                    position: absolute;
		                z-index: 10;
		                top: 100%;
		                left: 50%;
		                border-radius: 4px;
		                padding: 10px 0;
                    	width: 220px;
                    	min-width: 100%;
                    	background-color: #fff;
				        filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.2));
                    	opacity: 0;
                		visibility: hidden;
                		transform: translateX(-50%);
                    	transition: all .25s ease-in-out;
                    	&:before {
                    		content: "";
                    		position: absolute;
                    		top: -10px;
                    		left: 50%;
                    		margin-left: -10px;
                    		width: 0;
							height: 0;
							border-left: 10px solid transparent;
							border-right: 10px solid transparent;
							border-bottom: 10px solid #fff;
                    	}
	                }

	                > li {
	                    > a, > span:not([class*="expand"]) {
                			display: block;
			    			padding: 10px 20px;
							color: #5a5a5a;
							font-size: 0.875rem;
							font-weight: 400;
							line-height: 1.5;
			        		text-decoration: none;
			        		transition: all .25s ease-in-out;
			        		cursor: pointer;
			        		@include mq-up($lg) {
								padding: 5px 20px;
			        		}
	                    }

	                    &:hover, &.active {
	                        > a, > span:not([class*="expand"]) {
	                        	color: $primary;
	                        }
	                    }

	                    & + li {
			            	border-top: 1px solid #e5e5e5;
			            	@include mq-up($lg) {
					            border-top: 0;
					        }
			            }

	                    &.logout {
	                    	@include mq-up($lg) {
								margin-top: 5px;
				            	border-top: 1px solid #e5e5e5;
				            }
			            	> a, > span:not([class*="expand"]) {
			                    color: $primary;
			                    @include mq-up($lg) {
									padding-top: 10px;
				        		}
			                }
			            }
	                }
	            }
	        }
		}
	}

	.top-menu {
		padding-top: 10px;
		padding-bottom: 10px;
		@include mq-up($lg) {
			margin-left: 20px;
			padding-top: 0;
			padding-bottom: 0;
		}
	}

	.user-menu {
		padding-top: 25px;
		padding-bottom: 10px;
		text-align: center;
		@include mq-up($lg) {
			margin-left: 35px;
			padding-top: 0;
			padding-bottom: 0;
			text-align: left;
		}
		.profil-user {
			margin: 0 auto 15px;
			border-radius: 50%;
			width: 115px;
			height: 115px;
			overflow: hidden;
			@include mq-up($lg) {
				margin: 0;
				width: 50px;
				height: 50px;
			}
			img {
				width: 100%;
				height: auto;
			}
		}
	}

	.toggle-menu {
	    display: block;
	    position: absolute;
	    top: 10px;
	    right: 20px;
	    padding: 16px 9px;
	    width: 30px;
		height: 16px;
	    cursor: pointer;
	    box-sizing: content-box;
	    @include mq-up($lg) {
			display: none;
	    }
	    span {
	    	display: block;
	    	position: absolute;
		    border-radius: 1px;
		    width: 30px;
		    height: 2px;
		    background-color: #5a5a5a;
		    transition: background-color .075s ease;
		    &:nth-child(1) {
				top: 16px;
			    transition: top .075s ease .12s, transform .075s cubic-bezier(.55,.055,.675,.19);
		    }
		    &:nth-child(2) {
		    	top: 50%;
			    margin-top: -1px;
			    transition: opacity .075s ease;
		    }
		    &:nth-child(3) {
		    	bottom: 16px;
		    	right: 9px;
		    	width: 18px;
				transition: bottom .075s ease .12s, transform .075s cubic-bezier(.55,.055,.675,.19);
		    }
	    }

		&.open {
			span {
				width: 25px;
				background-color: $primary;
			    &:nth-child(1) {
					top: 22px;
					transform: rotate(45deg);
					transition: top .075s ease, transform .075s cubic-bezier(.215,.61,.355,1) .12s;
			    }
			    &:nth-child(2) {
				    opacity: 0;
				    transition: opacity .075s ease .12s;
			    }
			    &:nth-child(3) {
			    	bottom: 24px;
			    	right: auto;
					transition: bottom .075s ease, transform .075s cubic-bezier(.215,.61,.355,1) .12s;
					transform: rotate(-45deg);
			    }
			}
		}
	}

	&.scrolled {
		background-color: #fff;
		box-shadow: 0px 20px 40px 0px rgba(0, 0, 0, 0.15);
		.logo {
			> a {
				box-shadow: none;
				@include mq-down($lg) {
					padding-top: 5px;
					padding-bottom: 5px;
				}
			}
			> span {
				@include mq-down($lg) {
					display: none;
				}
			}
		}
	}
}

@include mq-down($lg) {
	.main-header {
	    #nav {
	        position: fixed;
	        top: 0;
	        right: 0;
	        bottom: 0;
	        left: 0;
	        padding: 170px 0 30px;
	        width: 100%;
	        height: 100%;
	        background-color: #f3f3f3;
	        overflow-x: auto;
	        overflow-y: scroll;
	        opacity: 0;
	        visibility: hidden;
	        transition: all .3s ease;
	        &:before {
	        	content: "";
	        	position: absolute;
	        	z-index: -1;
	        	top: 0;
	        	left: 0;
	        	width: 100%;
	        	height: 170px;
	        	background-color: #fff;
	        }
	    }
    }

    body.menu-open {
		overflow: hidden;
		.logo {
			a {
				background-color: transparent;
				box-shadow: none;
			}
		}
        #nav {
            opacity: 1;
            visibility: visible;
        }
	}
}
