.btn {
  display: inline-block;
  vertical-align: middle;
  padding: 22px 35px;
  border: 1px solid transparent;
  border-radius: 0;
  background-color: transparent;
  color: #212529;
  font-size: 0.75rem;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  cursor: pointer;
  outline: 0;
  user-select: none;
  text-decoration: none !important;
  transition: all .15s ease-in-out;

  &.btn-primary, &.btn1 {
    background-color: $primary;
    border-color: $primary;
    color: #fff;
    &:hover {
      background-color: darken($primary, 7.5%);
      border-color: darken($primary, 10%);
      color: #fff;
    }
  }

  &.btn-secondary, &.btn1 {
    background-color: $secondary;
    border-color: $secondary;
    color: #fff;
    &:hover {
      background-color: darken($secondary, 7.5%);
      border-color: darken($secondary, 10%);
      color: #fff;
    }
  }

  &.btn-black {
    background-color: #000;
    border-color: #000;
    color: #fff;
    &:hover {
      background-color: darken($primary, 7.5%);
      border-color: darken($primary, 10%);
      color: #fff;
    }
  }

  &.btn-white {
    background-color: #fff;
    border-color: #fff;
    color: $primary;
    &:hover {
      background-color: darken($primary, 7.5%);
      border-color: darken($primary, 10%);
      color: #fff;
    }
  }
}
input[type="submit"] {
  @extend .btn;
  @extend .btn-primary;
}