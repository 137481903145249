.paragraph--galerie {
	padding-top: 60px;
	padding-bottom: 35px;
  .swiper-container {
    padding-left: 30px;
    padding-right: 30px;
    .swiper-wrapper {
      align-items: center;
    }
    .swiper-slide {
      height: 430px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
      &.swiper-slide-active {
        height: 450px;
        @include mq-down($md) {
          height: 430px;
        }
      }
    }
    .swiper-button-prev, .swiper-button-next {
      background-image: none;
      color: $secondary;
      width: auto;
      height: auto;
      font-size: 2rem;
    }
  }
}
