.breadcrumb {
 ul, ol {
   display: flex;
   flex-wrap: wrap;
   margin: 0;
   padding: 0;
   list-style: none;
   justify-content: center;
   li {
     color: $color-body;

     a {
       color: $color-body;
       text-decoration: underline;
       &:hover {
         text-decoration: none;
       }
     }

     & + li {
       padding-left: .5rem;

       &::before {
         content: quote("/");
         display: inline-block;
         padding-right: .5rem;
       }
     }
   }
 }
}