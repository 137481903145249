// -------------------------------------------------------------
//   Types
// -------------------------------------------------------------

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-top: 0;
  margin-bottom: .5rem;
  color: inherit;
  font-family: inherit;
  font-weight: 700;
  line-height: normal;
  &:last-child {
    margin-bottom: 0;
  }
}

h1, .h1 {
  font-size: 2.5rem;
}
h2, .h2 {
  font-size: 2.15rem;
}
h3, .h3 {
  font-size: 1.75rem;
}
h4, .h4 {
  font-size: 1.5rem;
}
h5, .h5 {
  font-size: 1.25rem;
}
h6, .h6 {
  font-size: 1rem;
}

p, ul, ol {
  margin-top: 0;
  margin-bottom: 1rem;
  &:last-child {
    margin-bottom: 0;
  }
}

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    color: darken($primary, 15%);
    text-decoration: underline;
  }
}