// -------------------------------------------------------------
//   Main footer
//   Footer styles
// -------------------------------------------------------------

.main-footer {
	padding: 40px 0;
	background-color: #babec3;
	color: #fff;
	font-size: 0.875rem;
	line-height: 1.7;
	text-align: center;
	@include mq-up($sm) {
		padding-top: 25px;
		padding-bottom: 25px;
	}
	a:not([class*="btn"]) {
		color: #fff;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}
}
