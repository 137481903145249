.alert {
  position: relative;
  margin: 0;
  border-radius: 0;
  border: 1px solid transparent;
  padding: .75rem 1.25rem;
  padding-right: 4rem;

  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  
  a:not([class*="close"]) {
      font-weight: 700;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
  }

  .close {
    position: absolute;
    top: 0;
    right: 0;
    padding: .75rem 1.25rem;
    color: inherit;
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    text-shadow: 0 1px 0 #fff;
    text-decoration: none;
    opacity: .5;
    transition: all .2s;
    &:hover,
    &:focus {
      opacity: .75;
    }
  }
  &.alert-success {
      color: #155724;
      background-color: #d4edda;
      border-color: #c3e6cb;
      a {
        color: #155724;
      }
  }
  &.alert-danger {
      color: #721c24;
      background-color: #f8d7da;
      border-color: #f5c6cb;
      a {
        color: #721c24;
      }
  }
  &.alert-warning {
      color: #856404;
      background-color: #fff3cd;
      border-color: #ffeeba;
      a {
        color: #856404;
      }
  }
  &.alert-info {
      color: #0c5460;
      background-color: #d1ecf1;
      border-color: #bee5eb;
      a {
        color: #0c5460;
      }
  }
}

.messages {
  position: fixed;
  z-index: 1250;
  top: 0;
  left: 0;
  width: 100%;
}