.paragraph--equipe {
  padding-top: 60px;
  padding-bottom: 60px;
  h2 {
    text-align: center;
  }
  .members {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-left: -15px;
    margin-right: -15px;
  }
  .member {
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
    color: #646464;
    text-align: center;
    @include mq-up($sm) {
      flex: 0 0 50%;
      max-width: 50%;
    }
    @include mq-up($md) {
      flex: 0 0 33.33333%;
      max-width: 33.33333%;
    }
    @include mq-up($lg) {
      flex: 0 0 25%;
      max-width: 25%;
    }
    .img-wrapper {
      margin-bottom: 30px;
      border-radius: 50%;
      overflow: hidden;
      img {
        max-width: 100%;
        height: auto;
      }
    }
    h3 {
      margin-bottom: 0;
      color: #2B2B2B;
      font-size: 1rem;
      line-height: 1.5;
    }
    .function {
      margin-bottom: 0;
      color: $color-body;
      font-size: 0.9rem;
      font-style: italic;
      line-height: 1.7;
    }
  }
}
