.admin-navtabs {
	ul {
		display: flex;
		position: fixed;
		z-index: 500;
		right: 0;
		bottom: 0;
		margin: 0;
		padding: 0;
		list-style: none;
		li {
			& + li {
				margin-left: 1px;
			}
			a {
				display: block;
				padding: 10px 15px;
				background-color: $primary;
				color: #fff !important;
				text-decoration: none !important;
				transition: background-color .2s;
				&:hover {
					background-color: darken($primary, 15%);
				}
			}
		}
	}
}