// -------------------------------------------------------------
//   Main layout
//   Defines basic main styles in layout
// -------------------------------------------------------------

*,
*::before,
*::after {
  box-sizing: border-box;
}

body > svg {
    display: none;
}

body {
  background-color: #fff;
  color: $color-body;
  font-family: $ff-body;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
}
body.overlay-open {
  overflow: hidden;
}

.section-wrapper {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  max-width: $xxl;
  @include mq-down($xxl) {
    max-width: $xl;
  }
  @include mq-down($xl) {
    max-width: $lg;
  }
  @include mq-down($lg) {
    max-width: $md;
  }
  @include mq-down($md) {
    max-width: $sm;
  }
  @include mq-down($sm) {
    max-width: 100%;
  }
  &.md {
    @include mq-up($md) {
      max-width: $md;
    }
  }
  &.lg {
    @include mq-up($lg) {
      max-width: $lg;
    }
  }
  &.xl {
    @include mq-up($xl) {
      max-width: $xl;
    }
  }
}

.main-wrapper {
  position: relative;
  padding-top: 145px;
  overflow: hidden;
  @include mq-up($sm) {
    padding-top: 115px;
  }
  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    left: 50%;
    width: 2300px;
    height: 100%;
    background-image: url(../images/bg.png);
    background-position: center top;
    background-repeat: no-repeat;
    background-size: 2300px auto;
    transform: translateX(-50%);
  }
}

.cols {
 display: grid;
 grid-gap: 20px;
 margin-bottom: 50px;
 &:last-child {
  margin-bottom: 0;
 }
 @include mq-up($lg) {
   grid-gap: 40px;
 }
 &.cols_2 {
   @include mq-up($md) {
     grid-template-columns: repeat(2, 1fr);
   }
 }
 &.cols_3 {
   @include mq-up($lg) {
     grid-template-columns: repeat(3, 1fr);
   }
 }
}

.iframe-embed {
  display: block;
  position: relative;
  padding: 0;
  width: 100%;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    padding-top: percentage(9 / 16);
  }
  
  iframe,
  embed,
  object,
  video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%;
  }
}

.bg {
  position: absolute;
  z-index: 0;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.path-sitemap, .path-search, .path-user, .error-404, .page-node-type-webform {
 .main-wrapper {
   @extend .section-wrapper;
   padding-top: 60px;
   padding-bottom: 60px;
 }
}
.path-search, .error-404 {
 .main-wrapper {
   form {
     padding-bottom: 10px;
     margin-bottom: 20px;
   }
   input[type="submit"] {
     margin-top: 10px;
   }
   ol {
     li {
       h3 {
         a {
           font-size: 1.6rem;
           font-weight: bold;
         }
       }
     }
   }
 }
}