.webform-submission-form {
  display: grid;
  grid-gap: 20px 30px;
  @include mq-up($md) {
    grid-template-columns: repeat(2, 1fr);
    > * {
      grid-column-start: 1;
      grid-column-end: span 2;
    }
    > .column {
      grid-column-start: auto;
      grid-column-end: auto;
    }
  }
}

.form-text, .form-select, .form-email, .form-textarea, .form-search {
  display: block;
  border: 1px solid #ced4da;
  border-radius: .25rem;
  padding: 1rem 1.5rem;
  width: 100%;
  background-color: #fff;
  background-clip: padding-box;
  box-shadow: inset 0 1px 1px rgba(#000, .075);
  color: #495057;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.5;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

  &:focus {
    border-color: lighten($primary, 25%);
    box-shadow: inset 0 1px 1px rgba(#000, .075), 0 0 0 .2rem rgba($primary, .25);
    outline: 0;
  }

  &::placeholder {
    color: #6c757d;
  }
}

select.form-select[size], select.form-select[multiple],
textarea.form-textarea {
  height: auto;
}

.form-file,
.form-control-range {
  display: block;
  width: 100%;
}

.js-form-type-radio,
.js-form-type-checkbox {
  display: block;
  position: relative;
  padding-left: 1.25rem;
  .form-radio,
  .form-checkbox {
    position: absolute;
    margin-top: .3rem;
    margin-left: -1.25rem;
  }
  .option {
    margin-bottom: 0;
  }
}

// Custom Checkboxes and radios
.js-form-type-radio,
.js-form-type-checkbox {
  padding-left: 1.5rem;
  min-height: 1.5rem;
  .form-radio,
  .form-checkbox {
    z-index: -1;
    margin: 0;
    opacity: 0;
    &:checked {
      & ~ .option {
        &::before {
          border-color: $primary;
          background-color: $primary;
          box-shadow: none;
          color: #fff;
        }
      }
    }
    &:focus {
      & ~ .option {
        &::before {
          box-shadow: 0 0 0 .2rem rgba($primary, .25);
        }
      }
    }
    &:focus:not(:checked) {
      & ~ .custom-control-label {
        &::before {
          border-color: lighten($primary, 25%);
        }
      }
    }
  }
  .option {
    position: relative;
    vertical-align: top;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: .25rem;
      left: -1.5rem;
      border: 1px solid #adb5bd;
      width: 1rem;
      height: 1rem;
      background-color: #dee2e6;
      box-shadow: inset 0 1px 1px rgba(#000, .075);
      pointer-events: none;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: .25rem;
      left: -1.5rem;
      width: 1rem;
      height: 1rem;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
  }
}
.js-form-type-checkbox {
  .form-checkbox {
    &:checked {
      & ~ .option {
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='%23ffffff' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3e%3c/svg%3e");
        }
      }
    }
  }
  .option {
    &::before {
      border-radius: .25rem;
    }
  }
}
.js-form-type-radio {
  .form-radio {
    &:checked {
      & ~ .option {
        &::after {
          background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3e%3cpath fill='%23ffffff' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z'/%3e%3c/svg%3e");
        }
      }
    }
  }
  .option {
    &::before {
      border-radius: 50%;
    }
  }
}

// Custom select menu
.form-select {
  padding-right: 1.75rem;
  @include svgBackground("0 0 320 512", "#343a40", "M143 352.3L7 216.3c-9.4-9.4-9.4-24.6 0-33.9l22.6-22.6c9.4-9.4 24.6-9.4 33.9 0l96.4 96.4 96.4-96.4c9.4-9.4 24.6-9.4 33.9 0l22.6 22.6c9.4 9.4 9.4 24.6 0 33.9l-136 136c-9.2 9.4-24.4 9.4-33.8 0z");
  background-position: right .75rem center;
  background-repeat: no-repeat;
  background-size: 8px auto;
  appearance: none;

  &[multiple],
  &[size]:not([size="1"]) {
    padding-right: .75rem;
    background-image: none;
  }
}

// Custom file
.js-form-type-managed-file {
  display: inline-block;
  position: relative;
  margin-bottom: 0;
  width: 100%;
  height: calc(2.25rem + 2px);
  .form-file {
    position: relative;
    z-index: 2;
    width: 100%;
    height: calc(2.25rem + 2px);
    margin: 0;
    opacity: 0;

    &:focus ~ .custom-file-label {
      border-color: lighten($primary, 25%);
      box-shadow: 0 0 0 0.2rem rgba($primary, .25);
    }
  }
  label {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1;
    height: calc(2.25rem + 2px);
    padding: .375rem .75rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    box-shadow: inset 0 1px 1px rgba(#000, .075);

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 3;
      display: block;
      height: 2.25rem;
      padding: .375rem .75rem;
      line-height: 1.5;
      color: #495057;
      content: "Browse";
      background-color: #e9ecef;
      border-left: inherit;
      border-radius: 0 .25rem .25rem 0;
    }
  }
}